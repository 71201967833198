import { AiProvider, AiModelType, AiModel, TraceData } from "../../aiConstants";

export interface IAiTokenUsage {
    id: string
    createdAt: number
    modifiedAt: number
    provider: AiProvider
    modelType: AiModelType
    model: AiModel
    promptTokenCount: number
    completionTokenCount: number
    price?: {
        prompt: number
        completion: number
    },
    traceData?: TraceData
    totalCost?: number
}

import { IGenericModelFields } from "./IGenericFields";

export interface ISlideRevision extends IGenericModelFields {
    slideId: string;
    revisionTimestamp: number;
    revisionCreatedBy: string;
    revisionIsInitialState: boolean;
    advanceDelay: number;
    advanceOn: string;
    animations: any;
    audioAsset: string;
    layout: any;
    migrationVersion: number;
    slide_notes: string;
    states: any;
    template_id: string;
    template_version: number;
    timeline: any;
    version: number;
}

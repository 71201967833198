import { IGenericModelFields } from "./IGenericFields";

export interface ISlide extends IGenericModelFields {
    _changeId: string;
    createdBy: string;
    userId: string;
    advanceDelay: number;
    advanceOn: string;
    animations: any;
    assets: any;
    assignedPendingUser: string;
    assignedUser: string;
    audioAsset: string;
    clientId: string;
    debugInfo: any;
    isGallerySlide: boolean;
    isTeamSlideTemplate: boolean;
    isSkipped: boolean;
    layout: any;
    libraryItemId: string;
    migrationVersion: number;
    pendingUserAssignedBy: string;
    presentationId: string;
    slide_notes: string;
    status: string;
    states: any;
    sharedModel: any;
    template_id: string;
    template_version: number;
    timeline: any;
    thumbnails: any;
    version: number;
}

import { ISlide } from "./models";

export interface ISlidesMigratorRequest {
    slide: ISlide;
}

export interface ISlidesMigratorSuccessfulResponse {
    migratedSlide: ISlide;
}

export interface ISlidesMigratorErrorResponse {
    error: string;
}

export type ISlidesMigratorResponse = ISlidesMigratorSuccessfulResponse | ISlidesMigratorErrorResponse;

export interface IPresentationMetadata {
    id: string;
    name: string;
    createdAt: number;
    modifiedAt: number;
    slideIds: string[];
    firstSlideModifiedAt: number;
    slideCount: number;
    trashedAt: number;
    permissions: {
        owner: boolean;
        write: boolean;
        read: boolean;
    };
    workspaceId: string;
    isTemplate: boolean;
    isPublished: boolean;
    ownerUid: string;
    ratedByUsers: string[];
    metadataGeneratedAt: number;
}

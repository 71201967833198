import type { ISharedResource } from "./ISharedResource";
import { IGenericModelFields } from "./IGenericFields";

export interface IFolder extends IGenericModelFields {
    name: string;
    orgId?: string;
    userId?: string;
    teamId?: string;
    teamMemberIds?: string[];
    parentFolderId?: string;
    subFolderIds?: string[];
    sharedResources?: ISharedResource[];
    onlyOwnerCanEdit: boolean;
    createdAt: number;
    modifiedAt: number;
}

// currently team-folders are stored in the same collection as teams
// user-folders firebase table only contain user"s personal folders
export interface IUserFolderFirebase {
    id: string;
    _changeId: string;
    userId: string;
    orgId?: string;
    name: string;
    presentations?: { // {  "0": "presentationId" }
        [key: string]: string;
    };
    subFolders?: {
        [key: string]: ISubFolderFirebase;
    };
    createdAt: number;
    modifiedAt: number;
}

export interface ISubFolderFirebase {
    id: string;
    name: string;
    presentations?: { // {  "0": "presentationId" }
        [key: string]: string;
    };
}

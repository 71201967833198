export interface IFirebaseListenerPayload<T> {
    event: {
        data: T | null;
        delta: Partial<T> | null;
    };
    context: {
        operationType: "create" | "update" | "delete";
        params: {
            id: string;
        };
        firebaseTable: string;
        resource: string;
        timestamp: string;
        eventId: string;
        // Can be added manually before invoking the hooks
        metadata?: Record<string, any>;
    }
}

export interface ITeamAsset {
    id: string;
    createdAt: number;
    createdById: string;
    assetName: string;
    alt: string;
    hidden: boolean;
    actualId: string;
    tags: { [idx: string]: string };
    name: string;
    type: string;
}

import { WorkspaceUserRole } from "./IWorkspace";
import { IGenericModelFields } from "../IGenericFields";

export interface IWorkspaceUserInvite extends IGenericModelFields {
    readonly workspaceId: string;
    role: WorkspaceUserRole;
    readonly email: string;
    readonly validUntil: number;
    acceptedAt?: number;
    acceptedBy?: string;
}

export interface ILibraryItem {
    _changeId: string;
    contentId: string;
    createdAt: number;
    createdBy: string;
    description: string;
    id: string;
    isDisabled: boolean;
    isTemplate: boolean;
    contentModifiedAt: number;
    name: string;
    teamId: string;
    template_id: string;
    type: string;
    usedByUsers: Record<string, boolean>;
    usedInPresentations: Record<string, boolean>;
    tags: { [tag: string]: true };
    ratedByUsers: { [uid: string]: true };
}

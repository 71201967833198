export interface ICollectionEvent<ICollectionDocument> {
    id: string
    createdAt: number
    clusterTimestamp: { $timestamp: { t: number, i: number } }
    collectionName: string
    operationType: string
    documentId: string
    oldDocument: ICollectionDocument
    newDocument: ICollectionDocument
    updateDescription: {
        updatedFields?: {
            [key: string]: any
        }
        disambiguatedPaths?: {
            [key: string]: any[]
        }
        removedFields?: string[]
        truncatedArrays?: { field: string, newSize: number }[]
    }
}

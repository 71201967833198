import { IGenericModelFields } from "./IGenericFields";

export interface IOrganization extends IGenericModelFields {
    adminUids: string[];
    defaultTeamId: string;
    teamIds: string[];
    name?: string;
    totalSeatCount: number;
    stripe?: {
        customerId: string;
        subscriptionId: string;
        subscriptionStatus?: string;
    };
    sso?: {
        strict: boolean;
        allowIdpInitiated: boolean;
        skipDomainVerification: boolean;
        domains: [{
            domain: string;
            verificationKey: string;
            verificationError: string;
            verifiedAt: number;
            isVerified: boolean;
        }];
        samlConfig: {
            audience: string;
            callbackUrl: string;
            cert: string;
            certs: string[];
            entryPoint: string;
            idpIssuer: string;
            issuer: string;
            signatureAlgorithm: string;
            samlSSOEndpoint: string;
        };
        userProvisioning: {
            allowJit: boolean;
            allowScim: boolean;
            scimToken: string;
        };
    };
    createdAt: number;
    modifiedAt: number;
}

export interface IOrganizationFirebase {
    id: string;
    _changeId: string;
    adminUids: {
        [userId: string]: boolean;
    };
    defaultTeamId: string;
    teams?: {
        [teamId: string]: boolean;
    };
    isDomainVerified: boolean;
    domain: string;
    domainVerificationKey: string;
    domainVerificationError: string;
    domainVerifiedAt: number;
    skipDomainVerification: boolean;
    sso?: {
        allowIdpInitiated: boolean;
        samlConfig: {
            audience: string;
            callbackUrl: string;
            cert: string;
            certs: string[];
            entryPoint: string;
            idpIssuer: string;
            issuer: string;
            signatureAlgorithm: string;
            samlSSOEndpoint: string;
        };
        strict: boolean;
    };
    userProvisioning?: {
        allowJit: boolean;
        allowScim: boolean;
        scimToken: string;
    };
    totalSeatCount: number;
    customerId: string;
    stripeSubscriptionId: string;
    subscriptionStatus: string;
    createdAt: number;
    modifiedAt: number;
}

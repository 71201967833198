import { IGenericModelFields } from "./IGenericFields";

export interface IPresentation extends IGenericModelFields {
    _changeId: string;
    createdAt: number;
    modifiedAt: number;

    // general info
    name: string;
    userId: string;
    orgId: string;
    sharedThemeId: string;
    metadata: any,
    gDriveFileId: string;
    slideRefs: any;
    deletedSlides: any;
    skippedSlides: any;
    firstSlideModifiedAt: number;
    thumbnailTimestamp: number;
    lastViewedAt: number;
    softDeletedAt: number;
    sourcePresentationId: string;

    // random flags
    public: boolean;
    secured: boolean;
    isTemplate: boolean;
    isPublished: boolean;
    isImportedFromPPT: boolean;
    isTemplateConfirmed: boolean;
    hideControls: boolean;
    removeBeautifulBranding: boolean;
    showSocialSharing: boolean;
    showGetDeckUI: boolean;
    showAuthor: boolean;
    showTitle: boolean;
    requireEmail: boolean;
    allowPdfDownload: boolean;
    autoLoop: any;
    autoPlay: any;
    autoPlayDuration: number;
    tags: { [tag: string]: true };
    ratedByUsers: { [uid: string]: true };

    // theme related fields
    theme: any;
    theme_logo: any;
    theme_logo_dark: any;
    theme_showLogo: any;
    theme_logoPosition: any;
    theme_logoOffset: any;
    theme_logoScale: any;
    theme_showMessage: any;
    theme_showPageNum: any;
    theme_showFooterByDefault: any;
    theme_footerMessage: any;
    theme_palette_name: any;
    theme_defaultSlideColor: any;
    theme_defaultBackgroundColor: any;
    theme_styleElementStyle: any;
    theme_styleTitleFont: any;
    theme_styleBodyFont: any;
    theme_styleFontWeight: any;
    theme_styleEffect: any;
    theme_styleDesign: any;
    theme_styleShape: any;
    theme_styleWeight: any;
    theme_styleBackground: any;
    theme_styleDecoration: any;
    theme_styleTitleSlide: any;
    theme_colors: any;
    theme_iconStyle: any;
    theme_fontScale: any;
    theme_cjkFont: any;
    theme_isRTL: any;

    theme_fontHeaderFontId: any;
    theme_fontHeaderWeight: any;
    theme_fontHeaderBoldWeight: any;
    theme_fontHeaderLetterSpacing: any;
    theme_fontHeaderLineHeight: any;
    theme_fontHeaderScaling: any;
    theme_fontHeaderTextTransform: any;

    theme_fontTitleFontId: any;
    theme_fontTitleWeight: any;
    theme_fontTitleBoldWeight: any;
    theme_fontTitleLetterSpacing: any;
    theme_fontTitleLineHeight: any;
    theme_fontTitleScaling: any;
    theme_fontTitleTextTransform: any;

    theme_fontBodyFontId: any;
    theme_fontBodyWeight: any;
    theme_fontBodyBoldWeight: any;
    theme_fontBodyLetterSpacing: any;
    theme_fontBodyLineHeight: any;
    theme_fontBodyScaling: any;
    theme_fontBodyTextTransform: any;

    theme_chartColors: any;
    theme_backgroundImages: any;
    theme_backgroundGradients: any;

    // Obsolete fields, preserved to allow reusing thumbnails
    // that were generated before fonts refactor (to preserve
    // theme hashes which are generated based on this set of fields)
    theme_fontHeader: any;
    theme_fontHeaderRegular: any;
    theme_fontHeaderItalic: any;
    theme_fontHeaderEmphasized: any;
    theme_fontHeaderEmphasizedItalic: any;
    theme_fontTitle: any;
    theme_fontTitleRegular: any;
    theme_fontTitleItalic: any;
    theme_fontTitleEmphasized: any;
    theme_fontTitleEmphasizedItalic: any;
    theme_fontBody: any;
    theme_fontBodyRegular: any;
    theme_fontBodyItalic: any;
    theme_fontBodyEmphasized: any;
    theme_fontBodyEmphasizedItalic: any;

    theme_customStyles: any;
    theme_lastThemeEditor: any;
}

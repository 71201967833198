export interface IGenericMigrationInfoFields {
    migrationInfo?: {
        migratedAt: number;
        migratedBy: string;
        originalEntityId?: string;
        originalEntityType?: string;
        additionalInfo?: Record<string, any>;
    }
}

export interface IGenericModelFields extends IGenericMigrationInfoFields {
    readonly id: string;
    createdAt: number;
    modifiedAt: number;
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

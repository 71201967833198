/**
 * Fake puppeteer options so we don't have to depend on puppeteer
 */
export interface IBrowserOptions {
    executablePath?: string;
    ignoreDefaultArgs?: boolean | string[];
    handleSIGINT?: boolean;
    handleSIGTERM?: boolean;
    handleSIGHUP?: boolean;
    timeout?: number;
    dumpio?: boolean;
    env?: Record<string, string | undefined>;
    pipe?: boolean;
    waitForInitialPage?: boolean;
    headless?: boolean | "shell";
    userDataDir?: string;
    devtools?: boolean;
    debuggingPort?: number;
    args?: string[];
    ignoreHTTPSErrors?: boolean;
    defaultViewport?: any;
    slowMo?: number;
    targetFilter?: any;
    protocol?: any;
    protocolTimeout?: number;
}

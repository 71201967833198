import { IGenericModelFields } from "./IGenericFields";

export interface IProvisioningOauth2Metadata {
    sid: string;
}

export interface IProvisioningOauth2AccessToken extends IGenericModelFields {
    createdAt: number;
    modifiedAt: number;
    accessToken: string;
    expiresAt: number;
    clientId: string;
    uid: string;
    metadata: IProvisioningOauth2Metadata;
    scope: string[];
}

export interface IProvisioningOauth2RefreshToken extends IGenericModelFields {
    createdAt: number;
    modifiedAt: number;
    refreshToken: string;
    expiresAt: number;
    clientId: string;
    uid: string;
    metadata: IProvisioningOauth2Metadata;
    scope: string[];
}

export interface IProvisioningOauth2AuthorizationCode extends IGenericModelFields {
    createdAt: number;
    modifiedAt: number;
    authorizationCode: string;
    expiresAt: number;
    redirectUri: string;
    clientId: string;
    uid: string;
    metadata: IProvisioningOauth2Metadata;
    scope: string[];
}
